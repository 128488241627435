import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

// https://codebushi.com/form-handling-gatsby-netlify/

const ContactPage = () => (
  <Layout>
    <SEO title="Let's talk about your project"/>
    <section id="about">
      <div className="container">
        <div className=" t2-padding">
          <h1>Let's talk about your project</h1>
          <h4>Impactful Image annotation for AI Applications.</h4>
        </div>
      </div>
    </section>

    <section id="white-ground" className="t-padding b-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <form method="post" data-netlify="true" data-netlify-honeypot="bot-field" name="contact" action="/success/">
              <input type="hidden" name="bot-field"/>
              <input type="hidden" name="form-name" value="contact"/>
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Your name</label>
                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="first / last name" name="name"/>
              </div>

              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Email address</label>
                <input type="email" className="form-control" id="exampleFormControlInput2" placeholder="hello@example.com" name="email"/>
              </div>

              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Message</label>
                <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" name="message"></textarea>
              </div>

              <div className="form-group">
                <button className="button--red button--rounded" type="submit">Submit</button>
              </div>
            </form>
          </div>
          <div className="col-md-6">
            <div className="box-item">
              <h3 className="t-padding-sm">Impactful</h3>
              <p>Working with us creates impact in two ways:</p>
              <ol className="pt-3 text-left">
                <li>It provides local NGO’s with the means to offer training for free.</li>
                <li className="pt-3">It provides fair income to trainees so they don’t have to live on the street.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ContactPage
